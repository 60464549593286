@import '../../variables.scss';

.box {
    border: 0.3rem solid $color-logo-blue;
    border-radius: 0.6rem;
    color: white;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    & > h1 {
        color: white;
        margin: 0;
    }
}
