@import '../../variables.scss';

.container {
  margin-top: 2rem;
  box-sizing: border-box;
  background: black;
  width: 100%;
  padding: 2rem;
  height: auto;
}

.programContainer {
  display: flex;
  align-items: center;
  @media only screen and (max-width: $screen-sm) {
    display: block;
    text-align: start;
    padding-bottom: 2rem;
  }
}

.program {
  display: block;
  text-align: start;
  text-decoration: none;
  color: inherit;
  margin-bottom: 1rem;


  outline: none;
  transition: .3s;

  &:hover {
    outline: solid white;
    outline-offset: 0.5rem;
  }

  width: calc(100% - 160px);

  @media only screen and (max-width: $screen-sm) {
    width: 100%;
    margin-bottom: 2rem;
  }

}

.registrationLink{
  width: 180px;
  height: fit-content;
  margin-left: 20px;
  @media only screen and (max-width: $screen-sm) {
    margin-left: 0;
  }
}

.session{
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 1.5rem;
}

.speaker {
  color: $color-logo-orange;
  font-size: 1.3rem;
}

.subinfo{
  font-size: 1rem;
}