@import '../../variables.scss';

.navigation {
    display: flex;
    flex-direction: row;
    height: 5rem;
    color: white;
    background: #191919;
    width: 100%;
    box-sizing: border-box;
    z-index: 100;
    padding: 1rem;
    font-family: 'lunatix', serif;
    font-weight: bold;
    @media only screen and (max-width: $screen-xs) {
        padding: 1rem;
        box-sizing: border-box;
    }
    &.frontpage {
        box-sizing: border-box;
        background: none;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        @media only screen and (max-width: $screen-xs) {
            padding: 1rem;
        }

        @media only screen and (max-width: $screen-xxxs) {
            background: #191919;
        }
    }
    a {
        color: white;
        text-decoration: none;
    }
    &:before {
        opacity: 0;
    }
    &Sticky {
        position: fixed;
        top: 0;
    }
    &End {
        justify-content: flex-end;
        right: 0;
    }
    &NavItem {
        margin: 0.32rem 0.5rem 0 0.5rem;
        padding: 0.4rem 0.4rem;
        font-size: 1.5rem;
        opacity: 0.70;
        border-bottom: 2px solid transparent; 
        transition: opacity 300ms, border-bottom 300ms;
        @media only screen and (max-width: $screen-xs) {
            opacity: 1;
        }
        &:hover {
            border-bottom: 2px solid white;
            opacity: 1;
        }
    }
    &Brand {
        display: flex;
        flex-direction: row;
        align-items: center;
        opacity: 0.70;
        transition: opacity 300ms;
        @media only screen and (max-width: $screen-xs) {
            opacity: 1;
        }
        &:hover {
            opacity: 1;
        }
        h1 {
            margin: 0;
            font-size: 1.5rem;
            padding: 0 1rem;
        }
        img {
            width: 3rem;
            height: 3rem;
        }
    }
}

.active {
    border-bottom: 2px solid white;
    opacity: 1;
    @media only screen and (max-width: 900px) {
        border-bottom: none;
    }
}

.space {
    justify-content: space-between;
}

.menu {
    background: none;
    border: none;
    img {
        width: 32px;
        height: 32px;
    }
}

.menuContainer {
    position: fixed;
    display: flex;
    text-align: end;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0.5rem 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background: $color-logo-pink;
}