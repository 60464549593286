@import '../../variables.scss';

.preLine{
  white-space: pre-line;
}

.registrationLink{
  margin-top: 1rem;
}

.vimeo{
    height: 209px;
    width: 280px;

    @media (min-width: $screen-sm) {
      height: 380px;
      width: 678px;
    }

    @media (min-width: $screen-md){
      height: 519px;
      width: 924px;
    }
}

.vimeoWrapper {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}