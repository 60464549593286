@import '../../variables.scss';

.colorPink{
    h1, h2, h3, h4, h5 {
        color: $color-logo-pink
    }
}
.colorBlue{
    h1, h2, h3, h4, h5 {
        color: $color-logo-blue
    }
}
.colorGreen{
    h1, h2, h3, h4, h5 {
        color: $color-logo-green
    }
}

.section {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    @media only screen and (max-width: $screen-xxxs) {
        padding: 0.5rem
    }
}

.container {
    width: 75%;
    text-align: center;
    color: white;
    @media only screen and (max-width: $screen-md) {
        width: 100%;
    }
    h1, h2, h3, h4, h5 {
        text-transform: uppercase;
    }
}
